import React from "react"
import styled from "styled-components"
import { Btn1One } from "../../styles/helpers"

const LoadMoreBtn = ({ postsStore, loadMorePostsHandler, activeCat }) => {
  return (
    <LoadMoreBtnStyled>
      <button
        disabled={!postsStore.more}
        onClick={loadMorePostsHandler}
        type="button"
      >
        {postsStore.more
          ? `LOAD MORE ${activeCat === "all" ? "" : activeCat} POSTS`
          : `NO MORE ${activeCat === "all" ? "" : activeCat} POSTS`}
      </button>
    </LoadMoreBtnStyled>
  )
}

const LoadMoreBtnStyled = styled.section`
  width: 100%;
  margin-top: 5rem;
  text-align: center;

  button {
    ${Btn1One};
  }
`

export default LoadMoreBtn
