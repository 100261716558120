import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import TitleIntro from "../components/resources/TitleIntro"
import Posts from "../components/resources/Posts"

const resources = props => {
  const titleIntro = props?.data?.titleIntro?.template?.resources
  return (
    <Layout>
      <Seo title={`Resources`} />
      <TitleIntro data={titleIntro} />
      <Posts />
    </Layout>
  )
}

export const resourcesQuery = graphql`
  query resourcesTempPage($id: String!) {
    # seoInfo: wpPage(id: { eq: $id }) {
    #   seoFields {
    #     swbThemeDescription
    #     swbThemeMetaTitle
    #     swbThemeImage {
    #       localFile {
    #         relativePath
    #       }
    #     }
    #   }
    # }

    titleIntro: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_Resources {
          templateName
          resources {
            titleIntroTitle
            titleIntroContent
          }
        }
      }
    }
  }
`

export default resources
