import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { B1DarkBlue, B2DarkBlue, colors } from "../../styles/helpers"

const Card = ({ post, index }) => {
  const imgDisplay = getImage(
    post.node.post.featuredImage.localFile.childImageSharp.gatsbyImageData
  )
  const imgAlt = post.node.post.featuredImage.altText
  return (
    <PostCard
      firstpost={index % 3 === 0}
      id={post.node.id}
      to={`/resources/${post.node.slug}`}
    >
      <div className="image">
        <div className="image__container">
          <GatsbyImage
            image={imgDisplay}
            alt={imgAlt}
            layout="fullWidth"
            formats={["auto", "webp", "avif"]}
          />
        </div>
      </div>
      <div className="content">
        <div className="content__title">
          <h3>{post.node.title}</h3>
        </div>
        <div className="content__excerpt">
          <p>{post.node.post.postExcerpt}</p>
        </div>
      </div>
    </PostCard>
  )
}

const PostCard = styled(Link)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 3.5rem;

  @media (min-width: 768px) {
    width: calc(33.333333% - 1rem);
    margin-bottom: 1.5rem;
    margin-left: ${props => (props.firstpost ? "0rem" : "1.5rem")};
  }

  .image {
    position: relative;
    width: 100%;
    height: 15rem;

    @media (min-width: 768px) {
      height: 24rem;
    }

    &__container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      .gatsby-image-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100%);
        height: 100%;

        img {
          width: 100% !important;
        }
      }
    }
  }

  .content {
    width: 100%;
    padding: 3.1rem;
    flex-grow: 1;
    border: solid 1px ${colors.colorTertiary};
    background-color: rgba(146, 166, 186, 0.1);

    &__title {
      width: 100%;
      margin-bottom: 2rem;
      padding-bottom: 2rem;
      border-bottom: 0.1rem solid ${colors.colorPrimary};

      h3 {
        ${B1DarkBlue};
        margin: 0;
        font-weight: bold;
      }
    }

    &__excerpt {
      width: 100%;

      p {
        ${B2DarkBlue};
      }
    }
  }
`

export default Card
