import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import { medWrapper } from "../../styles/helpers"
import Card from "./Card"
import CatNav from "./CatNav"
import LoadingModal from "./LoadingModal"
import LoadingCatModal from "./LoadingCatModal"
import LoadMoreBtn from "./LoadMoreBtn"

const getData = graphql`
  {
    posts: allWpPost(sort: { fields: date, order: DESC }) {
      edges {
        node {
          post {
            postExcerpt
            featuredImage {
              altText
              localFile {
                url
                childImageSharp {
                  gatsbyImageData(width: 2000)
                }
              }
            }
          }
          title
          id
          date
          slug
          categories {
            nodes {
              name
              slug
            }
          }
        }
      }
    }

    category: allWpCategory {
      edges {
        node {
          name
          slug
          id
        }
      }
    }
  }
`

const Posts = () => {
  const postsData = useStaticQuery(getData)
  const posts = postsData.posts.edges
  const categories = postsData.category.edges

  const DISPLAY_NUMBER = 6
  const [activeCat, setActiveCat] = useState("all")

  const setNewActiveCat = cat => {
    setActiveCat(cat)
    setPostsStoreStore(prevState => {
      return {
        ...prevState,
        catChange: false,
      }
    })
  }

  const [postsStore, setPostsStoreStore] = useState({
    max: 0,
    current: 0,
    catPosts: [],
    display: [],
    loading: false,
    catChange: false,
  })

  useEffect(() => {
    setPostsStoreStore(prevState => {
      return {
        ...prevState,
        max: posts?.length,
        current: DISPLAY_NUMBER,
        catPosts: posts.filter(post => {
          if (
            activeCat !== "all" &&
            activeCat !== post.node.categories.nodes[0].slug
          ) {
            return false
          } else {
            return true
          }
        }),
        display: posts.slice(0, DISPLAY_NUMBER),
        more: posts?.length > DISPLAY_NUMBER,
      }
    })
  }, [activeCat])

  const getMorePosts = () => {
    setPostsStoreStore(prevState => {
      return {
        ...prevState,
        current: prevState.current + DISPLAY_NUMBER,
        display: prevState.catPosts.slice(
          0,
          prevState.current + DISPLAY_NUMBER
        ),
        more: prevState.max > prevState.current + DISPLAY_NUMBER,
        loading: false,
      }
    })
  }

  const loadMorePostsHandler = () => {
    setPostsStoreStore(prevState => {
      return {
        ...prevState,
        loading: true,
      }
    })

    setTimeout(() => {
      getMorePosts()
    }, 2000)
  }

  useEffect(() => {
    setPostsStoreStore(prevState => {
      return {
        ...prevState,
        max: prevState.catPosts.length,
        display: prevState.catPosts.slice(0, DISPLAY_NUMBER),
        more: prevState.catPosts.length > DISPLAY_NUMBER,
      }
    })
  }, [postsStore.catPosts])

  const loadSetActiveCat = cat => {
    setPostsStoreStore(prevState => {
      return {
        ...prevState,
        catChange: true,
      }
    })

    setTimeout(() => {
      setNewActiveCat(cat)
    }, 2000)
  }

  return (
    <StyledSection>
      <div className="wrapper">
        <CatNav
          categories={categories}
          activeCat={activeCat}
          loadSetActiveCat={loadSetActiveCat}
        />
        {postsStore.display.length > 0 &&
          postsStore.display.map((post, index) => {
            if (
              activeCat !== "all" &&
              activeCat !== post.node.categories.nodes[0].slug
            )
              return null
            return <Card post={post} index={index} />
          })}
        <LoadMoreBtn
          postsStore={postsStore}
          loadMorePostsHandler={loadMorePostsHandler}
          activeCat={activeCat}
        />
      </div>
      {postsStore.loading && <LoadingModal activeCat={activeCat} />}
      {postsStore.catChange && <LoadingCatModal />}
    </StyledSection>
  )
}

const StyledSection = styled.section`
  .wrapper {
    ${medWrapper};
    justify-content: flex-start;
  }
`

export default Posts
