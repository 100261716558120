import React from "react"
import styled from "styled-components"
import { colors, B1DarkBlue } from "../../styles/helpers"

const CatNav = ({ categories, activeCat, loadSetActiveCat }) => {
  return (
    <CatNavStyled>
      <ul>
        <li>
          <button
            className={"all" === activeCat ? "activeCat" : ""}
            onClick={() => {
              loadSetActiveCat("all")
            }}
          >
            All
            <span>&#124;</span>
          </button>
        </li>
        {categories.map((cat, index) => {
          return (
            <li key={cat.node.id}>
              <button
                className={cat.node.slug === activeCat ? "activeCat" : ""}
                onClick={() => {
                  loadSetActiveCat(cat.node.slug)
                }}
              >
                {cat.node.name}
                {categories.length !== index + 1 ? (
                  <span className="pipe">&#124;</span>
                ) : null}
              </button>
            </li>
          )
        })}
      </ul>
    </CatNavStyled>
  )
}

const CatNavStyled = styled.div`
  width: 100%;
  padding: 4rem 0;

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    li {
      width: 100%;
      margin: 1rem auto;

      @media (min-width: 768px) {
        width: auto;
        margin: 0 0.5rem;
      }

      button {
        ${B1DarkBlue};
        width: 100%;
        background-color: transparent;
        border: none;
        font-weight: 300;
        transition: all 0.3s ease;
        text-transform: uppercase;
        cursor: pointer;

        @media (min-width: 768px) {
          width: auto;
        }

        &:focus {
          box-shadow: none;
          color: ${colors.colorPrimary};
        }

        &:hover {
          color: ${colors.colorPrimary};
        }

        &.activeCat {
          color: ${colors.colorPrimary};
          font-weight: 700;
        }

        span {
          display: none;

          @media (min-width: 768px) {
            display: inline-block;
            margin-left: 1rem;
          }
        }
      }
    }
  }
`

export default CatNav
